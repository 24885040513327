import { Component, ElementRef, ViewChild } from '@angular/core';

import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import Stats from 'three/examples/jsm/libs/stats.module'
import * as dat from 'dat.gui'

import * as GaussianSplats3D from '@mkkellogg/gaussian-splats-3d';

declare let Ammo: any;

@Component({
  selector: 'app-three-viewer',
  templateUrl: './three-viewer.component.html',
  styleUrls: ['./three-viewer.component.scss']
})
export class ThreeViewerComponent {
  @ViewChild('rendererContainer', { static: true }) rendererContainer!: ElementRef<HTMLDivElement>;
  private renderer = new THREE.WebGLRenderer();
  private scene = new THREE.Scene();
  private camera =  new THREE.PerspectiveCamera();
  private raycaster = new THREE.Raycaster();
  private pointer = new THREE.Vector2(0, 0);
  private loader = new OBJLoader();
  private three = {
    fov: 50,
  }
  

  ngOnInit(): void {
    window.addEventListener('resize', this.onWindowResize.bind(this), false);
    window.addEventListener('pointermove', this.onPointerMove.bind(this), false);
  }

  public load(path: string = '/assets/ply/room11_full_wide.ply') {
    const renderWidth = window.innerWidth;
    const renderHeight = window.innerHeight;

    const rootElement = document.createElement('div');
    rootElement.style.width = renderWidth + 'px';
    rootElement.style.height = renderHeight + 'px';

    document.body.appendChild(rootElement);

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x000000);
    this.renderer = new THREE.WebGLRenderer({
      antialias: false
    });
    this.renderer.setSize(renderWidth, renderHeight);
    rootElement.appendChild(this.renderer.domElement);

    console.log(this.three.fov);

    this.camera = new THREE.PerspectiveCamera(this.three.fov, renderWidth / renderHeight, 0.1, 400);
    this.camera.position.copy(new THREE.Vector3().fromArray([0, 1, 0]));
    this.camera.up = new THREE.Vector3().fromArray([0, -1, 0]).normalize();
    this.camera.lookAt(new THREE.Vector3().fromArray([1, 0, 0]));

    // this.loader.load('/assets/obj/bugatti.obj', (mesh: any) => {
    //   console.log(mesh.scene);
    //   this.scene.add(mesh);
    // }, undefined, (error) => {
    //   console.error(error);
    // });
    // this.loader.load(
    //   '/assets/obj/bugatti.obj',
    //   (object) => {
    //     console.log(object);
    //     this.scene.add(object);
    //   },
    //   (xhr) => {
    //     console.log((xhr.loaded / xhr.total * 100) + '% loaded');
    //   },
    //   (error) => {
    //     console.log( 'An error happened' );
    //   }
    // );

    const geometry = new THREE.BoxGeometry()
    const material = new THREE.MeshBasicMaterial({
      color: 0x00ff00,
      wireframe: true,
    })
    
    const cube = new THREE.Mesh(geometry, material)
    
    this.scene.add(cube)

    const gui = new dat.GUI({autoPlace: true})
    const cubeFolder = gui.addFolder('Cube')
    cubeFolder.add(cube.rotation, 'x', 0, Math.PI * 2)
    cubeFolder.add(cube.rotation, 'y', 0, Math.PI * 2)
    cubeFolder.add(cube.rotation, 'z', 0, Math.PI * 2)
    cubeFolder.open()
    const cameraFolder = gui.addFolder('Camera')
    cameraFolder.add(this.three, 'fov', 10.0, 160.0)
      .name('change fov')
      .onChange(()=> {
        this.camera.fov = this.three.fov;
        this.camera.updateProjectionMatrix();
      })
    cameraFolder.open()

    const viewer = new GaussianSplats3D.Viewer({
      selfDrivenMode: false,
      renderer: this.renderer,
      camera: this.camera,
      useBuiltInControls: true,
      ignoreDevicePixelRatio: false,
      gpuAcceleratedSort: true,
      enableSIMDInSort: true,
      sharedMemoryForWorkers: true,
      integerBasedSort: true,
      halfPrecisionCovariancesOnGPU: true,
      dynamicScene: true,
      webXRMode: GaussianSplats3D.WebXRMode.None,
      renderMode: GaussianSplats3D.RenderMode.OnChange,
      sceneRevealMode: GaussianSplats3D.SceneRevealMode.Default,
      antialiased: true,
      focalAdjustment: 1.0,
      logLevel: GaussianSplats3D.LogLevel.None,
      sphericalHarmonicsDegree: 0,
      enableOptionalEffects: false,
      plyInMemoryCompressionLevel: 2,
      freeIntermediateSplatData: true
    });
    viewer.addSplatScene(path, {
      threeScene: this.scene,
      format: GaussianSplats3D.SceneFormat.Ply
    })
    .then(() => {
      const update = () => {
        this.raycaster.setFromCamera(this.pointer, this.camera);

        const intersects = this.raycaster.intersectObjects(this.scene.children);

        for (let i = 0; i < intersects.length; i++) {
          console.log(intersects);
          const object = intersects[i].object as THREE.Mesh;
          
          if (object.material instanceof THREE.MeshBasicMaterial) {
            console.log((object.material as THREE.MeshBasicMaterial).color);
            (object.material as THREE.MeshBasicMaterial).color.set(0xff0000);
          }
        }

        this.renderer.render(this.scene, this.camera);

        viewer.update();
        viewer.render();

        requestAnimationFrame(update);
      }
      requestAnimationFrame(update);
    });
  }

  public fileChange(event: any) {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file); 
    
    this.load(url);
  }

  private onWindowResize(): void {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  private onPointerMove(event: any) {
    this.pointer.x = ((event.clientX / window.innerWidth) * 2 - 1);
    this.pointer.y = (-(event.clientY / window.innerHeight) * 2 + 1);
  }
  
}
