import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-chromakey',
  templateUrl: './chromakey.component.html',
  styleUrls: ['./chromakey.component.scss']
})
export class ChromakeyComponent {
  @ViewChild('videoElement', { static: true }) videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('videoSynthesisElement', { static: true }) videoSynthesisElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement', { static: true }) canvasElement!: ElementRef<HTMLCanvasElement>;

  public remover: any = { color: 0 };
  public offset: number = 10;

  ngOnInit(): void {
    let _remover = localStorage.getItem('remover');

    if (_remover) {
      this.remover = JSON.parse(_remover);
    }
  }

  public Hex2RGB(hexColor: string) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);

    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
  
  public onChange (event: any) {
    let rgb = this.Hex2RGB(this.remover.color);

    this.remover.rgb = rgb;

    localStorage.setItem('remover', JSON.stringify(this.remover, null, 2));
  }

  public onCanPlay() {
    const video = this.videoElement.nativeElement;
    const replaceVideo = this.videoSynthesisElement.nativeElement;
    const canvas = this.canvasElement.nativeElement;
    
    video.style.opacity = "0";
    
    const context = canvas.getContext('2d');
    
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    
    this.updateCanvas(context as CanvasRenderingContext2D, video, replaceVideo);
  }

  public onFileSelectedOriginal(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      this.videoElement.nativeElement.src = url;
      this.videoElement.nativeElement.play();
    }
  }
  public onFileSelectedSynthesis(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      this.videoSynthesisElement.nativeElement.src = url;
      this.videoSynthesisElement.nativeElement.play();
    }
  }

  public updateCanvas(context: CanvasRenderingContext2D, video: HTMLVideoElement, replaceVideo: HTMLVideoElement): void {
    if (!video.paused && !video.ended) {
      let canvas = document.createElement('canvas');
      
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      
      let replacer = canvas.getContext('2d') as CanvasRenderingContext2D;
      
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      replacer.drawImage(replaceVideo, 0, 0, video.videoWidth, video.videoHeight);

      this.chromakey_transparent(context, video.videoWidth, video.videoHeight, this.remover.rgb, this.offset, replacer);
      setTimeout(() => this.updateCanvas(context, video, replaceVideo), 0);
    }
  }


  public RGB2YUV(r: number, g: number, b: number): { y: number, u: number, v: number } {
    // const y = 0.299 * r + 0.587 * g + 0.114 * b;
    // const u = -0.14713 * r - 0.28886 * g + 0.436 * b;
    // const v = 0.615 * r - 0.51498 * g - 0.10001 * b;

    const y = 0.257 * r + 0.504 * g + 0.098 * b + 16;
    const u = -0.148 * r - 0.291 * g + 0.439 * b + 128;
    const v = 0.439 * r - 0.368 * g - 0.0071 * b + 128;

    return { y, u, v };
  }

  public chromakey_transparent(context: CanvasRenderingContext2D, width: number, height: number, targetColor: { r: number, g: number, b: number }, offset: number, replaceContext: CanvasRenderingContext2D): void {
    const frame = context.getImageData(0, 0, width, height);
    const replaceFrame = replaceContext.getImageData(0, 0, width, height);
    const length = frame.data.length;

    const targetYUV = this.RGB2YUV(targetColor.r, targetColor.g, targetColor.b);

    for (let i = 0; i < length; i += 4) {
      const r = frame.data[i];
      const g = frame.data[i + 1];
      const b = frame.data[i + 2];

      const { y, u, v } = this.RGB2YUV(r, g, b);

      const yDiff = Math.abs(y - targetYUV.y);
      const uDiff = Math.abs(u - targetYUV.u);
      const vDiff = Math.abs(v - targetYUV.v);

      // if (yDiff < offset && uDiff < offset && vDiff < offset) {
      if (yDiff + uDiff + vDiff < offset) {
        frame.data[i] = replaceFrame.data[i];
        frame.data[i + 1] = replaceFrame.data[i + 1];
        frame.data[i + 2] = replaceFrame.data[i + 2];
        // frame.data[i + 3] = 0;
      }
    }

    context.putImageData(frame, 0, 0);
  }
}
