<div class="board-wrapper">
  <div class="control-panel">
    <div class="control-panel-row">
      <label>origin: </label>
      <input type="file" accept="video/*" (change)="onFileSelectedOriginal($event)">
    </div>
    <div class="control-panel-row">
      <label>synthesis: </label>
      <input type="file" accept="video/*" (change)="onFileSelectedSynthesis($event)">
    </div>
    <div class="control-panel-row">
      <label>target: </label>
      <input type="color" [(ngModel)]="remover.color" (change)="onChange($event)">
    </div>
    <div class="control-panel-row">
      <label>offset: </label>
      <input type="number" [(ngModel)]="offset" min="0" max="765">
    </div>
  </div>
  <canvas class="board-canvas" #canvasElement></canvas>
  <video class="board-video" #videoElement autoplay (canplaythrough)="onCanPlay()"></video>
  <video class="board-video" #videoSynthesisElement autoplay style="opacity: 0;"></video>
</div>
