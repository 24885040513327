import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThreeViewerComponent } from './page/three-viewer/three-viewer.component';
import { AppComponent } from './app.component';
import { ChromakeyComponent } from './page/chromakey/chromakey.component';

const routes: Routes = [
  { path: 'gs', component: ThreeViewerComponent },
  { path: 'chromakey', component: ChromakeyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
